import { Utils } from "./utils.js";

export class AppModal {
  modalInfoElement
  modalAlertElement
  modalThanksElement
  modalInfo
  modalAlert
  modalThanks

  isInfoOpen
  isAlertOpen
  isThanksOpen

  modalsQueue

  constructor(modalInfoElement, modalAlertElement, modalThanksElement) {
    this.isInfoOpen = false;
    this.isAlertOpen = false;
    this.isThanksOpen = false;

    this.modalInfoElement = modalInfoElement
    this.modalAlertElement = modalAlertElement
    this.modalThanksElement = modalThanksElement

    this.modalInfo = new bootstrap.Modal(this.modalInfoElement, {});
    this.modalAlert = new bootstrap.Modal(this.modalAlertElement, {});
    this.modalThanks = new bootstrap.Modal(this.modalThanksElement, {});

    this.modalsQueue = [];

    this.setEvents();
  }

  // API :: INI
  info(message, callback = null) {
    this.addModalToQueue("info", message, callback);
  }

  alert(message, callback = null) {
    this.addModalToQueue("alert", message, callback);
  }

  thanks(callback = null) {
    this.addModalToQueue("thanks", "", callback);
  }
  // API :: END

  isOpen() {
    return this.isInfoOpen || this.isAlertOpen || this.isThanksOpen;
  }

  setEvents() {
    this.modalInfoElement.addEventListener('show.bs.modal', (event) => {
      this.isInfoOpen = true;
    });

    this.modalInfoElement.addEventListener('hidden.bs.modal', (event) => {
      this.isInfoOpen = false;
      this.modalClosed();
    });

    this.modalAlertElement.addEventListener('show.bs.modal', (event) => {
      this.isAlertOpen = true;
    });

    this.modalAlertElement.addEventListener('hidden.bs.modal', (event) => {
      this.isAlertOpen = false;
      this.modalClosed();
    });

    this.modalThanksElement.addEventListener('show.bs.modal', (event) => {
      this.isThanksOpen = true;
    });

    this.modalThanksElement.addEventListener('hidden.bs.modal', (event) => {
      this.isThanksOpen = false;
      this.modalClosed();
    });
  }

  showInfo(message, callback = null) {
    this.modalInfoElement.querySelector(".modal-body").innerHTML = Utils.markdown(message);
    this.showModal(this.modalInfo, this.modalInfoElement, callback);
  }

  showAlert(message, callback = null) {
    this.modalAlertElement.querySelector(".modal-body").innerHTML = Utils.markdown(message);
    this.showModal(this.modalAlert, this.modalAlertElement, callback);
  }

  showThanks(callback = null) {
    this.showModal(this.modalThanks, this.modalThanksElement, callback);

    setTimeout(() => {
      party.confetti(this.modalThanksElement, {
        count: party.variation.range(100, 200),
      });
    }, 1000);
  }

  showByKind(kind, message, callback = null) {
    switch (kind) {
      case "info":
        this.showInfo(message, callback);
        break;
      case "alert":
        this.showAlert(message, callback);
        break;
      case "thanks":
        this.showThanks(callback);
        break;
      default:
        throw new Error(`AppModal.show() kind not supported '${kind}'`);
        break;
    }
  }

  modalClosed() {
    this.nextModal();
  }

  addModalToQueue(kind, message, callback = null) {
    this.modalsQueue.push(new AppModal.ModalMessage(kind, message, callback));
    this.nextModal();
  }

  nextModal() {
    if (this.isOpen() || this.modalsQueue.length === 0) {
      return;
    }

    const nextModalMessage = this.modalsQueue.shift();
    this.showByKind(nextModalMessage.kind, nextModalMessage.message, nextModalMessage.callback);
  }

  static ModalMessage = class ModalMessage {
    kind
    message
    callback

    constructor(kind, message, callback = null) {
      this.kind = kind;
      this.message = message;
      this.callback = callback;
    }
  }

  showModal(modal, modalElement, callback = null) {
    modal.show();

    if (callback) {
      const abortController = new AbortController();
      modalElement.addEventListener("hide.bs.modal", () => {
        callback();
        abortController.abort();
      }, { signal: abortController.signal });
    }
  }
}

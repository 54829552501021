export class AudioStreamAnalyzer {
  #audioContext
  #audioSource
  #streamDataArray
  #streamAnalyser
  #definition
  #streamBufferLength

  constructor(audioContext, audioSource, definition = 32) {
    this.#audioContext = audioContext;
    this.#audioSource = audioSource;
    this.#definition = definition;

    this.#streamAnalyser = this.#audioContext.createAnalyser();
    this.#audioSource.connect(this.#streamAnalyser);

    this.#streamAnalyser.fftSize = this.#definition; // use 2048 for big definition
    this.#streamBufferLength = this.#streamAnalyser.frequencyBinCount;
    this.#streamDataArray = new Uint8Array(this.#streamBufferLength);
  }

  getData() {
    this.#streamAnalyser.getByteTimeDomainData(this.#streamDataArray);
    return this.#streamDataArray;
  }
}

import { MessageBus } from "./message_bus.js";

export class RecordingTester {
  recorder = null;
  videoElement = null;

  constructor(videoElement, recorder) {
    this.videoElement = videoElement;
    this.recorder = recorder;
  }

  // API :: INI
  test(seconds, shouldCloseStreamsOnFinish = false) {
    this.showRecording();

    return new Promise((resolve, reject) => {
      this.recorder.record(seconds, shouldCloseStreamsOnFinish)
        .then((blob) => {
          this.play(blob);
          resolve();
        })
        .catch((error) => {
          MessageBus.error("RecordingTester.test() - Error: ", error);
          reject(error);
        });
    })
  }
  // API :: END

  showRecording() {
    this.videoElement.srcObject = this.recorder.getFullStream();
    this.videoElement.muted = true;
    this.videoElement.play();
  }

  play(blob) {
    const videoURL = window.URL.createObjectURL(blob);
    this.videoElement.srcObject = null;
    this.videoElement.pause();
    this.videoElement.src = videoURL;
    this.videoElement.muted = false;
    this.videoElement.play();
  }
}

let recordingTester = null;

async function start() {
  const recorder = new SimpleRecorder();
  await recorder.initScreenStream();
  await recorder.initMicStream();
  recorder.initMediaRecorder();
  recordingTester = new RecordingTester(document.querySelector("#video-wrapper video"), recorder);
  recordingTester.test();
}

// start();

import { EventBus } from "./event_bus.js";
import { Effects } from "./effects.js";
import { MessageBus } from "./message_bus.js";
import { APP } from "./app.js";
import { Utils } from "./utils.js";

export class Page {
  steps
  developerRequirementsChecks
  termsAndConditionsCheck

  titlePortionElement
  playGatheringInfoBlock

  coverImgElement
  titleElement
  descriptionElement
  playtestingRewardBlock
  playtesterExpectationsBlock
  playtestingSessionDurationBlock
  fullGameplayDurationBlock
  genresBlock
  acceptPlaySessionButton
  developerRequirementsBlock
  extraInformationBlock
  afterSessionInfo
  afterThanksBlock

  stepsSectionBlock


  constructor({
    playGathering
  } = {}) {
    this.playGathering = playGathering

    // PlayGathering basic description
    this.titlePortionElement = document.querySelector("#title-portion");
    this.playGatheringInfoBlock = document.querySelector("#play-gathering-info-block");
    this.coverImgElement = document.querySelector("#play-gathering-cover-img");
    this.titleElement = document.querySelector("#play-gathering-title");
    this.descriptionElement = document.querySelector("#play-gathering-description");
    this.playtestingRewardBlock = document.querySelector("#play-gathering-playtesting-reward");
    this.playtesterExpectationsBlock = document.querySelector("#play-gathering-playtester-expectations");
    this.playtestingSessionDurationBlock = document.querySelector("#play-gathering-playtesting-session-duration");
    this.fullGameplayDurationBlock = document.querySelector("#play-gathering-full-gameplay-duration");
    this.genresBlock = document.querySelector("#play-gathering-genres");
    this.acceptPlaySessionButton = document.querySelector("#play-gathering-accept-play-session-button");
    this.developerRequirementsBlock = document.querySelector("#step-developer-requirements");
    this.extraInformationBlock = document.querySelector("#step-extra-information")
    this.afterSessionInfoBlock = document.querySelector("#step-after-session-info")
    this.afterThanksBlock = document.querySelector("#after-thanks");

    // Steps section
    this.steps = [];
    this.stepsSectionBlock = document.querySelector("#steps-section");
    this.stepDeveloperRequirements = new Page.Step(this.developerRequirementsBlock, this.steps);
    this.stepExtraInformation = new Page.Step(this.extraInformationBlock, this.steps);
    this.stepDownloadGame = new Page.Step(this.stepsSectionBlock.querySelector("#step-download-game"), this.steps);
    this.stepTermsAndConditions = new Page.Step(this.stepsSectionBlock.querySelector("#step-terms-and-conditions"), this.steps);
    this.stepRecordingRoom = new Page.Step(this.stepsSectionBlock.querySelector("#step-recording-room"), this.steps);
    this.stepFinalThoughts = new Page.Step(this.stepsSectionBlock.querySelector("#step-final-thoughts"), this.steps);
    this.stepAfterSessionInfo = new Page.Step(this.afterSessionInfoBlock, this.steps);

    this.termsAndConditionsCheck = this.stepTermsAndConditions.block.querySelector("input[type=checkbox]");

    // // TODO: remove this
    // Effects.delayedFadeIn(this.stepsSectionBlock);
    // this.stepRecordingRoom.show();
  }

  checkIfPlayGatheringIsStillOpen() {
    if (this.playGathering.isFinished) {
      Effects.delayedBlurIn(document.querySelector(".container"));
      MessageBus.info(
        "This PlaySession is already closed. Thank you so much for your interest in helping us testing this game ;)",
        () => {
          this.afterThanks();
        }
      )
      return false;
    } else {
      return true;
    }
  }

  init() {
    this.captureWindowErrors();
    this.setPageTitle();
    this.setEvents();
    this.setListeners();
    this.fillPlayGatheringInfo();
    this.showPlayGatheringInfo();
    this.scrollToTop(500);
  }

  setListeners() {
    EventBus.addListener(
      "recording-session-finished",
      () => { this.recordingSessionFinished(); }
    );

    EventBus.addListener(
      "final-thoughts-finished",
      () => { this.finalThoughtsFinished(); }
    );

    EventBus.addListener(
      "all-steps-finished",
      () => { this.allStepsFinished(); }
    );
  }

  setEvents() {
    // I'm in button
    this.acceptPlaySessionButton.addEventListener("click", () => {
      this.showStepsSectionBlock();
      Effects.delayedFadeOut(this.acceptPlaySessionButton);
      this.steps[0].show(500);
    });

    // Extra information next button
    this.stepExtraInformation.block.querySelector(".next-button").addEventListener("click", () => {
      this.stepExtraInformation.hide(500);
      this.stepExtraInformation?.next().show(1000);
    });

    // Download game next button
    this.stepDownloadGame.block.querySelector(".next-button").addEventListener("click", () => {
      this.stepDownloadGame.hide(500);
      this.stepDownloadGame?.next().show(1000);
    });

    // Terms and conditions
    this.termsAndConditionsCheck.addEventListener("click", () => {
      MessageBus.debug("Terms and Conditions accepted");
      APP.playcocolaAPI.sendSystemInfo();
      this.termsAndConditionsCheck.setAttribute("disabled", true);
      this.stepTermsAndConditions.hide(500);
      this.stepTermsAndConditions?.next().show(1000);
    });

    // After Session Done button
    this.stepAfterSessionInfo.block.querySelector(".next-button").addEventListener("click", () => {
      this.allStepsFinished()
    });

    // Window closes
    window.addEventListener("beforeunload", (event) => {
      this.windowIsClosing(event);
    }, { capture: true });
  }

  setPageTitle() {
    document.title = "Playcocola is testing " + this.playGathering.title;
    this.titlePortionElement.innerHTML = this.playGathering.title;
  }

  showStepsSectionBlock() {
    Effects.delayedFadeIn(this.stepsSectionBlock, 100);
  }

  developerRequirementsCheckChecked() {
    this.stepDeveloperRequirements.block.querySelectorAll("input[type=checkbox]:checked").forEach((element) => {
      element.setAttribute("disabled", true);
    });

    if (this.stepDeveloperRequirements.block.querySelectorAll("input[type=checkbox]:not(:checked)").length === 0) {
      this.stepDeveloperRequirements.hide();
      this.stepDeveloperRequirements?.next().show(500);
    }
  }

  fillPlayGatheringInfo() {
    // Basic
    if (this.playGathering.coverUrl) {
      this.coverImgElement.src = this.playGathering.coverUrl;
    } else {
      this.coverImgElement.remove();
      this.coverImgElement = null;
    }

    this.titleElement.innerHTML = this.playGathering.title;
    this.descriptionElement.innerHTML = Utils.markdown(this.playGathering.description);

    if (this.playGathering.playtestingSessionMinutes) {
      this.playtestingSessionDurationBlock.querySelector(".mins").innerHTML = this.playGathering.playtestingSessionMinutes;
    } else {
      this.playtestingSessionDurationBlock.remove();
      this.playtestingSessionDurationBlock = null;
    }

    if (this.playGathering.fullGameplayMinutes) {
      this.fullGameplayDurationBlock.querySelector(".mins").innerHTML = this.playGathering.fullGameplayMinutes;
    } else {
      this.fullGameplayDurationBlock.remove();
      this.fullGameplayDurationBlock = null;
    }

    if (this.playGathering.playtesterReward) {
      this.playtestingRewardBlock.querySelector(".card-text").innerHTML = Utils.markdown(this.playGathering.playtesterReward || "");
    } else {
      this.playtestingRewardBlock.remove();
      this.playtestingRewardBlock = null;
    }

    if (this.playGathering.playtesterExpectations) {
      this.playtesterExpectationsBlock.querySelector(".card-text").innerHTML = Utils.markdown(this.playGathering.playtesterExpectations || "");
    } else {
      this.playtesterExpectationsBlock.remove();
      this.playtesterExpectationsBlock = null;
    }

    if (this.playGathering.genres.length !== 0) {
      this.fillGenres(this.playGathering.genres)
    } else {
      this.genresBlock.remove();
      this.genresBlock = null;
    }

    // Steps
    if (this.playGathering.requiredCheckboxes.length !== 0) {
      this.fillDeveloperRequirements(this.playGathering.requiredCheckboxes);
    } else {
      this.stepDeveloperRequirements.remove();
      this.stepDeveloperRequirements = null;
    }

    if (this.playGathering.gameBuildUrl) {
      this.stepDownloadGame.stepContentElement.querySelector("a").href = this.playGathering.gameBuildUrl;
    } else {
      this.stepDownloadGame.remove();
      this.stepDownloadGame = null;
    }

    if (this.playGathering.afterRequiredCheckboxesDescription) {
      this.stepExtraInformation.setBody(this.playGathering.afterRequiredCheckboxesDescription);
    } else {
      this.stepExtraInformation.remove();
      this.stepExtraInformation = null;
    }

    if (this.playGathering.afterSessionFinishedDescription) {
      this.stepAfterSessionInfo.setBody(this.playGathering.afterSessionFinishedDescription);
    } else {
      this.stepAfterSessionInfo.remove();
      this.stepAfterSessionInfo = null;
    }
  }

  fillGenres(genres) {
    const templateBadge = this.genresBlock.querySelector("span");

    this.genresBlock.innerHTML = "";

    genres.forEach((genre) => {
      const newBadge = templateBadge.cloneNode(true);
      newBadge.innerHTML = genre;
      this.genresBlock.appendChild(newBadge);

      const space = document.createTextNode("\u00A0");
      this.genresBlock.appendChild(space);
    });
  }

  fillDeveloperRequirements(requirements) {
    const templateCheck = this.stepDeveloperRequirements.stepContentElement.querySelector(".form-check");

    this.stepDeveloperRequirements.stepContentElement.innerHTML = "";

    requirements.forEach((requirement) => {
      const id = crypto.randomUUID();
      const newCheck = templateCheck.cloneNode(true);
      newCheck.querySelector("input").id = id;
      newCheck.querySelector("label").htmlFor = id;
      newCheck.querySelector("label").innerHTML = Utils.markdown(requirement);

      newCheck.querySelector("input").addEventListener("change", () => {
        this.developerRequirementsCheckChecked();
      });

      this.stepDeveloperRequirements.stepContentElement.appendChild(newCheck);
    });
  }

  // Show Elements :: INI
  showPlayGatheringInfo() {
    Effects.delayedFadeIn(this.playGatheringInfoBlock);

    Effects.delayedFadeIn(this.coverImgElement, 500);
    Effects.delayedFadeIn(this.titleElement, 600);
    Effects.delayedFadeIn(this.descriptionElement, 800);
    Effects.delayedFadeIn(this.playtestingSessionDurationBlock, 1000);
    Effects.delayedFadeIn(this.fullGameplayDurationBlock, 1100);
    Effects.delayedFadeIn(this.playtesterExpectationsBlock, 900);
    Effects.delayedFadeIn(this.playtestingRewardBlock, 1500);
    Effects.delayedFadeIn(this.genresBlock, 750);
    Effects.delayedFadeIn(this.acceptPlaySessionButton, 1800);

    this.makeLinksTargetBlank(this.descriptionElement);
    this.makeLinksTargetBlank(this.playtestingRewardBlock);
    this.makeLinksTargetBlank(this.developerRequirementsBlock);
    this.makeLinksTargetBlank(this.extraInformationBlock);
    this.makeLinksTargetBlank(this.afterSessionInfoBlock);
  }
  // Show Elements :: END

  captureWindowErrors() {
    window.addEventListener("error", function (errorEvent) {
      MessageBus.error("An error was found :/", errorEvent.error);
    });
  }

  makeLinksTargetBlank(element) {
    if (!element) {
      return;
    }

    element.querySelectorAll("a").forEach((link) => { link.setAttribute('target', '_blank'); });
  }

  recordingSessionFinished() {
    this.stepRecordingRoom.hide();
    this.stepRecordingRoom?.next().show(500);
  }

  finalThoughtsFinished() {
    this.stepFinalThoughts.hide();
    this.stepFinalThoughts.next()?.show(500);
  }

  allStepsFinished() {
    Effects.delayedBlurIn(document.querySelector(".container"), 500);
    Effects.delayedFadeOut(this.stepsSectionBlock, 2500);

    setTimeout(() => {
      MessageBus.thanks(() => {
        this.afterThanks();
      });
    }, 2000);
  }

  afterThanks() {
    Effects.delayedFadeOut(this.playGatheringInfoBlock, 500);
    Effects.delayedFadeIn(this.afterThanksBlock, 500);
    Effects.delayedBlurOut(document.querySelector(".container"), 1000);
    this.scrollToTop();
  }

  windowIsClosing(event) {
    console.log("WindowIsClosing()");

    if (APP.uploader.isUploadingActive()) {
      event.preventDefault();
      return event.returnValue = "The video is currently uploading. Are you sure you want to close the window?";
    }

    if (APP.recordingManager.isRecording) {
      event.preventDefault();
      return event.returnValue = "The recording is currently active. Are you sure you want to close the window?";
    }
  }

  scrollToTop(delayMilliseconds = 0) {
    setTimeout(() => {
      document.querySelector("header").scrollIntoView({ behavior: "smooth", top: 0 });
    }, delayMilliseconds);
  }

  static Step = class Step {
    block
    headerButtonElement
    collapsableElement
    stepContentElement
    isRemoved
    allSteps

    collapsable

    constructor(collapsableElement, allSteps) {
      this.collapsableElement = collapsableElement;
      this.block = this.collapsableElement.closest(".accordion-item");
      this.headerButtonElement = this.block.querySelector(".accordion-header");
      this.stepContentElement = this.block.querySelector(".step-content");

      this.collapsable = bootstrap.Collapse.getOrCreateInstance(collapsableElement, { toggle: false });

      this.isRemoved = false;

      this.allSteps = allSteps;
      this.allSteps.push(this);
    }

    setBody(markdownText) {
      this.stepContentElement.innerHTML = Utils.markdown(markdownText);
    }

    show(delayMilliseconds = 0) {
      this.collapsable.show();
      Effects.delayedFadeIn(this.block, delayMilliseconds, true);
    }

    hide(delayMilliseconds = 0) {
      setTimeout(() => {
        this.collapsable.hide();
      }, delayMilliseconds);
    }

    remove() {
      this.block.remove();

      const index = this.allSteps.indexOf(this);
      this.allSteps.splice(index, index !== -1 ? 1 : 0);

      this.isRemoved = true;
    }

    next() {
      const index = this.allSteps.indexOf(this);
      if ((index + 1) === this.allSteps.length) {
        EventBus.event("all-steps-finished");
        return null;
      } else {
        return this.allSteps[index + 1];
      }
    }
  }
}

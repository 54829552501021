import { SimpleRecorder } from "./recording.js";
import { RecordingTester } from "./recording_tester.js";
import { SimpleTimer } from "./timer.js";
import { EventBus } from "./event_bus.js";
import { Effects } from "./effects.js";
import { Utils } from "./utils.js";
import { MessageBus } from "./message_bus.js"
import { APP } from "./app.js";

export class RecordingManager {
  videoStatesBlock
  videoStatePlayingBadge
  videoStateRecordingBadge
  videoStatePausedBadge
  videoButtonsBlock
  testRecordingButton
  testReplayButton
  startRecordingButton
  recorderButtonsBlock
  pauseButton
  resumeButton
  finishRecordingButton
  videoMiniControlsBlock
  micCheck
  micCheckBlock
  micPopover
  commentButton
  timerDisplay
  uploadingProgressBlock
  videoElement

  timer
  isRecordingPaused
  isMicActive
  isSessionFinished
  isRecording

  recorder
  recordingTester
  micAnimationIntervalID
  // videoSettingsTrackIntervalID

  TESTING_SECONDS
  VIDEO_BLOCK_SECONDS

  constructor() {
    this.TESTING_SECONDS = 10;
    this.VIDEO_BLOCK_SECONDS = 60; // TODO back to 60

    this.videoStatesBlock = document.querySelector("#video-state-badges");
    this.videoStatePlayingBadge = document.querySelector("#video-state-playing-badge");
    this.videoStateRecordingBadge = document.querySelector("#video-state-recording-badge");
    this.videoStatePausedBadge = document.querySelector("#video-state-paused-badge");
    this.videoButtonsBlock = document.querySelector("#video-buttons-wrapper");
    this.testRecordingButton = document.querySelector("#test-recording-button");
    this.testReplayButton = document.querySelector("#test-replay-button");
    this.startRecordingButton = document.querySelector("#start-recording-button");
    this.recorderButtonsBlock = document.querySelector("#recorder-buttons");
    this.pauseButton = document.querySelector("#pause-button");
    this.resumeButton = document.querySelector("#resume-button");
    this.finishRecordingButton = document.querySelector("#finish-recording-button");
    this.videoMiniControlsBlock = document.querySelector("#video-mini-controls");
    this.micCheck = document.querySelector("#mic-check");
    this.micCheckBlock = document.querySelector("#mic-check-wrapper");
    this.micPopover = bootstrap.Popover.getOrCreateInstance("#mic-check-wrapper")
    this.commentButton = document.querySelector("#timed-comment-button");
    this.timerDisplay = document.querySelector("#timer-display");
    this.timedCommentTimerDisplay = document.querySelector("#timed-comment-timer-display");
    this.uploadingProgressBlock = document.querySelector("#uploading-progress-block");

    this.videoElement = document.querySelector("#video-wrapper video");
    this.videoElement.controls = false;

    this.isMicActive = this.micCheck.checked;
    this.isRecordingPaused = false;
    this.isSessionFinished = false;
    this.isRecording = false;

    this.recorder = new SimpleRecorder();
    this.recordingTester = new RecordingTester(this.videoElement, this.recorder);

    this.testRecordingVideoEventsController = new AbortController();

    this.setMicActive(false);

    this.timer = new SimpleTimer(() => { this.timerTick(); });

    this.setListeners();
    this.setEvents();

    // // TODO: remove this
    // this.videoSettingsTrackStart();
  }

  setListeners() {
    EventBus.addListener(
      "timed-comment-toggle-recording-pause",
      (value) => { this.askingForPause(value); }
    );
  }

  setEvents() {
    this.testRecordingButton.addEventListener("click", (event) => {
      this.testRecording();
    });

    this.testReplayButton.addEventListener("click", (event) => {
      this.videoElement.play();
      Effects.delayedFadeIn(this.videoStatePlayingBadge, 0);
      Effects.delayedFadeOut(this.micCheckBlock, 0);
    });

    this.micCheck.addEventListener("change", (event) => {
      this.setMicActive(this.micCheck.checked);
    });

    this.startRecordingButton.addEventListener("click", (event) => {
      console.log("startRecordingButton.click()");
      this.startRecording();
    });

    this.pauseButton.addEventListener("click", (event) => {
      this.pauseRecording();
    });

    this.resumeButton.addEventListener("click", (event) => {
      this.resumeRecording();
    });

    this.finishRecordingButton.addEventListener("click", (event) => {
      this.finishRecording();
    });
  }

  async initStreams() {
    await this.recorder.initStreams();
  }

  async testRecording() {
    this.recorder.resetStreams();
    this.recorder.resetMediaRecorder();

    await this.initStreams();

    // Add testing recording video events
    this.videoElement.addEventListener("ended", (event) => {
      this.testRecordingVideoFinished();
      Effects.delayedFadeOut(this.videoStatePlayingBadge);
      Effects.delayedFadeOut(this.videoStateRecordingBadge);
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
    }, { signal: this.testRecordingVideoEventsController.signal });

    this.videoElement.addEventListener("play", (event) => {
      this.testRecordingVideoPlaying();
    }, { signal: this.testRecordingVideoEventsController.signal });

    Effects.delayedFadeIn(this.videoStateRecordingBadge, 0);
    this.videoElement.removeAttribute("controls");

    this.timer.start();
    this.recordingTester.test(this.TESTING_SECONDS, true)
      .then(() => {
        Effects.delayedFadeIn(this.videoStatePlayingBadge, 0);
        Effects.delayedFadeOut(this.videoStateRecordingBadge, 0);
        Effects.delayedFadeOut(this.micCheckBlock, 0);
        this.videoElement.setAttribute("controls", true);
      })
      .catch((error) => {
        APP.playcocolaAPI.sendDebugEvent("RecordingManager.testRecording() - Error: ", error);
        this.onErrorOnRecording(error);
      })
      .finally(() => {
        this.timer.pause();
      });
  }

  testRecordingVideoPlaying() {
    Effects.delayedFadeOut(this.videoButtonsBlock)
    Effects.delayedBlurOut(this.videoElement);
  }

  testRecordingVideoFinished() {
    Effects.delayedFadeIn(this.testReplayButton);
    Effects.delayedFadeIn(this.videoButtonsBlock, 500);
    Effects.delayedFadeIn(this.micCheckBlock, 1000);
    Effects.delayedBlurIn(this.videoElement, 500);
  }

  setMicActive(value) {
    console.log("App.setMicActive(): ", value);
    this.micCheck.checked = value;
    this.isMicActive = value;
    this.recorder.setMicActive(value);
    this.updateMicPopover();

    MessageBus.debug("Mic Activation: " + value);

    if (value) {
      this.micAnimationStart();
    } else {
      this.micAnimationStop();
    }
  }

  timerTick(timer) {
    this.timerDisplay.innerHTML = this.timer.timeToString();
    this.timedCommentTimerDisplay.innerHTML = this.timer.timeToString();
  }

  onErrorOnRecording(error) {
    MessageBus.error("RecordingManager.onErrorOnRecording()", error);

    this.isRecording = false;

    Effects.delayedFadeIn(this.videoButtonsBlock, 500);
    Effects.delayedBlurIn(this.videoElement, 500);

    Effects.delayedFadeOut(this.commentButton, 500);
    Effects.delayedFadeOut(this.recorderButtonsBlock, 1000);
    Effects.delayedFadeOut(this.videoStateRecordingBadge);

    this.timer.pause();
    this.videoElement.pause();
  }

  async startRecording() {
    try {
      this.recorder.resetStreams();
      this.recorder.resetMediaRecorder();
      await this.initStreams();
    } catch (error) {
      MessageBus.error("There was an error trying to record the screen", error);
      return;
    }

    this.sendTracksInfo();

    this.isRecording = true;

    // Remove testing recording video events
    this.testRecordingVideoEventsController.abort();

    Effects.delayedFadeOut(this.videoButtonsBlock);
    Effects.delayedBlurOut(this.videoElement);

    Effects.delayedFadeIn(this.commentButton, 500);
    Effects.delayedFadeIn(this.recorderButtonsBlock, 1000);
    Effects.delayedFadeIn(this.videoStateRecordingBadge);

    this.timer.start();

    this.videoElement.srcObject = this.recorder.getFullStream();
    this.videoElement.muted = true;
    this.videoElement.controls = false;
    this.videoElement.play();

    this.recordVideoBlock();

  }

  recordVideoBlock() {
    this.recorder.record(this.VIDEO_BLOCK_SECONDS)
      .then((blob) => {
        this.newVideoBlock(blob);
        if (!this.isRecordingPaused && !this.isSessionFinished) {
          this.recordVideoBlock();
        }
      })
      .catch((error) => {
        APP.playcocolaAPI.sendDebugEvent("RecordingManager.recordVideoBlock() - Error: ", error);
        this.onErrorOnRecording(error);
      });
  }

  newVideoBlock(blob) {
    console.log("RecordingManager.newVideoBlock().blob.size: ", blob.size);
    APP.playcocolaAPI.sendDebugEvent("RecordingManager.newVideoBlock().blob.size: " + blob.size);
    APP.uploader.addBlobToUploadQueue(blob);
  }

  pauseRecording() {
    if (this.isRecordingPaused) {
      return;
    }

    this.isRecordingPaused = true;
    APP.playcocolaAPI.sendDebugEvent("RecordingManager.pauseRecording()");

    Effects.delayedFadeOut(this.videoStateRecordingBadge);
    Effects.delayedFadeIn(this.videoStatePausedBadge, 100);

    this.pauseButton.setAttribute("disabled", true);
    this.resumeButton.removeAttribute("disabled");
    this.timer.pause();
    this.recorder.stop();
    this.videoElement.pause();
    this.micAnimationStop();
    Effects.delayedBlurIn(this.videoElement, 500);
  }

  resumeRecording() {
    if (!this.isRecordingPaused) {
      return;
    }

    this.isRecordingPaused = false;
    APP.playcocolaAPI.sendDebugEvent("RecordingManager.resumeRecording()");

    Effects.delayedFadeIn(this.videoStateRecordingBadge, 100);
    Effects.delayedFadeOut(this.videoStatePausedBadge);

    this.pauseButton.removeAttribute("disabled");
    this.resumeButton.setAttribute("disabled", true);
    this.timer.resume();
    this.videoElement.play();
    this.recordVideoBlock();

    if (this.isMicActive) {
      this.micAnimationStart();
    }

    Effects.delayedBlurOut(this.videoElement, 200);
  }

  finishRecording() {
    if (!confirm("Please confirm you want to finish the session")) {
      return;
    }

    this.isRecording = false;
    this.isSessionFinished = true;

    Effects.delayedFadeOut(this.videoStatesBlock);
    Effects.delayedFadeOut(this.videoMiniControlsBlock);
    Effects.delayedFadeOut(this.videoButtonsBlock);
    Effects.delayedFadeOut(this.recorderButtonsBlock);
    Effects.delayedBlurIn(this.videoElement, 200);

    this.timer.pause();
    this.recorder.stop(true);
    this.videoElement.pause();

    // Wait a bit to allow recorder to generate the last blob
    setTimeout(() => {
      if (APP.uploader.isUploadingActive()) {
        APP.uploader.onUploadsFinishedCallback = () => {
          APP.playcocolaAPI.sendSignalSessionFinalized();
        }
      } else {
        APP.playcocolaAPI.sendSignalSessionFinalized();
      }
    }, 500);

    EventBus.event("recording-session-finished");
  }

  askingForPause(value) {
    console.log("askingForPause: ", value);
    if (value) {
      this.pauseRecording();
    } else {
      this.resumeRecording();
    }
  }

  updateMicPopover() {
    if (this.micCheck.checked) {
      this.micPopover.setContent({ ".popover-body": "Mic is active, click to deactivate it." });
    } else {
      this.micPopover.setContent({ ".popover-body": "Mic is deactivated, click to activate it." });
    }
  }

  // Mic Animation :: INI
  micAnimation() {
    if (this.recorder.micAnalyzer) {
      const data = this.recorder.micAnalyzer.getData();

      const max = Math.max(...data);
      const min = Math.min(...data);
      const valueMax = Math.abs(128 - max);
      const valueMin = Math.abs(128 - min);
      const value = Math.max(valueMin, valueMax);
      const scale = Math.min(Utils.map_range(value, 0, 10, 1, 1.5), 1.5);

      this.micCheckBlock.style.transform = `scale(${scale},${scale})`;
    }
  }

  micAnimationStart() {
    this.micAnimationIntervalID =
      setInterval(() => {
        this.micAnimation();
      }, 300);
  }

  micAnimationStop() {
    this.micCheckBlock.style.transform = `scale(1,1)`;
    clearInterval(this.micAnimationIntervalID);
  }
  // Mic Animation :: END

  sendTracksInfo() {
    if (this.recorder.screenVideoTrack) {
      MessageBus.debug("screenVideoTrack: " + JSON.stringify(this.recorder.screenVideoTrack.getSettings()));
    } else {
      MessageBus.debug("No screenVideoTrack");
    }

    if (this.recorder.screenAudioTrack) {
      MessageBus.debug("screenAudioTrack: " + JSON.stringify(this.recorder.screenAudioTrack.getSettings()));
    } else {
      MessageBus.debug("No screenAudioTrack");
    }

    if (this.recorder.micAudioTrack) {
      MessageBus.debug("micAudioTrack: " + JSON.stringify(this.recorder.micAudioTrack.getSettings()));
    } else {
      MessageBus.debug("No micAudioTrack");
    }
  }

  // // Video Track Settings :: INI
  // videoSettingsTrack() {
  //   if (this.recorder.screenVideoTrack) {
  //     const settings = this.recorder.screenVideoTrack.getSettings();
  //     const constraints = this.recorder.screenVideoTrack.getConstraints();

  //     console.log("VideoTrack.settings: ", settings);
  //     console.log("VideoTrack.constraints: ", constraints);
  //   }
  // }

  // videoSettingsTrackStart() {
  //   this.videoSettingsTrackIntervalID =
  //     setInterval(() => {
  //       this.videoSettingsTrack();
  //     }, 5000);
  // }

  // videoSettingsTrackStop() {
  //   this.micCheckBlock.style.transform = `scale(1,1)`;
  //   clearInterval(this.videoSettingsTrackIntervalID);
  // }
  // // Video Track Settings :: END
}

import { Page } from "./page.js";
import { PlaycocolaAPI } from "./playcocola_api.js";
import { RecordingManager } from "./recording_manager.js";
import { Uploader } from "./uploader.js";
import { FinalThoughts } from "./final_thoughts.js";
import { TimedComment } from "./timed_comment.js";
import { Utils } from "./utils.js";

class App {
  modal
  page
  messageBus
  uploader
  finalThoughts
  timedComment
  playcocolaAPI
  recordingManager

  constructor() {
  }

  async init() {
    this.initPopovers();
    this.initRecordingManager();
    this.initUploader();
    this.initFinalThoughts();
    this.initTimedComment();

    await this.initPlaycocolaAPI();
    this.initPage();

    this.finalThoughts.sendInfoIfAlreadyRemembered();
  }

  initUploader() {
    this.uploader = new Uploader();
  }

  async initPlaycocolaAPI() {
    this.playcocolaAPI = new PlaycocolaAPI({
      playGatheringApiUrl: Utils.getParamValue("play_gathering_api_url"),
      apiToken: Utils.getParamValue("api_token")
    });

    await this.playcocolaAPI.intPlayGathering();
  }

  initPage() {
    this.page = new Page({
      playGathering: this.playcocolaAPI.playGathering
    });

    if (this.page.checkIfPlayGatheringIsStillOpen()) {
      this.page.init();
    }
  }

  initPopovers() {
    const popoverTriggerList = document.querySelectorAll('[data-bs-toggle="popover"]')
    const popoverList = [...popoverTriggerList].map(popoverTriggerEl => new bootstrap.Popover(popoverTriggerEl))
  }

  initRecordingManager() {
    this.recordingManager = new RecordingManager();
  }

  initFinalThoughts() {
    this.finalThoughts = new FinalThoughts();
  }

  initTimedComment() {
    this.timedComment = new TimedComment();
  }
}

const APP = new App();
APP.init();

window.APP = APP;

export { APP };

export class Effects {
  static delayedFadeIn(element, milliseconds = 0, scrollTo = false) {
    if (!element) {
      return;
    }

    element.classList.add("fade-out");

    setTimeout(() => {
      element.classList.remove("d-none");
      element.classList.remove("fade-out");
      element.classList.add("fade-in");
      if (scrollTo) {
        element.scrollIntoView({ behavior: "smooth", top: 0 });
      };
    }, milliseconds)
  }

  static delayedFadeOut(element, milliseconds = 0) {
    if (!element) {
      return;
    }

    setTimeout(() => {
      element.classList.add("d-none");
      element.classList.remove("fade-in");
      element.classList.add("fade-out");
    }, milliseconds)
  }

  static delayedBlurIn(element, milliseconds = 0) {
    if (!element) {
      return;
    }

    setTimeout(() => {
      element.classList.remove("blur-out");
      element.classList.add("blur-in");
    }, milliseconds)
  }

  static delayedBlurOut(element, milliseconds = 0) {
    if (!element) {
      return;
    }

    setTimeout(() => {
      element.classList.remove("blur-in");
      element.classList.add("blur-out");
    }, milliseconds)
  }
}

export class EventBus {
  static listeners = []

  static event(eventID, params = null) {
    console.log(`EventBus.event(${eventID}, ${params})`);

    EventBus.listeners.forEach(listener => {
      if (listener.eventID === eventID) {
        if (params) {
          listener.callback(params);
        } else {
          listener.callback();
        }
      }
    });
  }

  static addListener(eventID, callback) {
    const listener =
      new this.Listener(
        eventID,
        callback
      );

    return listener;
  }

  static Listener = class Listener{
    constructor(
      eventID,
      callback
    ) {
      this.eventID = eventID;
      this.callback = callback;
      EventBus.listeners.push(this);
    }

    remove() {
      const index = EventBus.listeners.indexOf(this);
      EventBus.listeners.splice(index, index !== -1 ? 1 : 0);
    }
  }
}

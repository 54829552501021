import { MessageBus } from "./message_bus.js";
import { EventBus } from "./event_bus.js";
import { APP } from "./app.js";

export class FinalThoughts {
  #form
  #cancelButton
  #submitButton
  #rememberInfoCheck

  constructor() {
    this.#form = document.querySelector("#final-thoughts-form");
    this.#cancelButton = document.querySelector("#final-thoughts-cancel-button");
    this.#submitButton = document.querySelector("#final-thoughts-submit-button");
    this.#rememberInfoCheck = document.querySelector("#final-thoughts-remember-info-check");

    this.#setEvents();
    this.#checkIfInfoIsRemembered();
    this.#checkIfSubmitButtonShouldBeEnabledOrDisabled();
  }

  // API :: INI
  sendInfoIfAlreadyRemembered() {
    if (
      (this.#form.querySelector("#final-thoughts-name").value !== "") ||
      (this.#form.querySelector("#final-thoughts-email").value !== "")
    ) {
      this.#sendFormInfo();
    }
  }
  // API :: END

  async #setEvents() {
    this.#form.addEventListener("submit", (event) => {
      this.#onFormSubmit(event);
    });

    this.#rememberInfoCheck.addEventListener("change", (event) => {
      this.#rememberOrForgetInfo();
    });

    this.#cancelButton.addEventListener("click", (event) => {
      EventBus.event("final-thoughts-finished");
    });

    this.#form.querySelectorAll(".form-control").forEach((element) => {
      element.addEventListener("input", (event) => {
        this.#checkIfSubmitButtonShouldBeEnabledOrDisabled();
      });
    });
  }

  #disableSubmitButton() {
    this.#submitButton.setAttribute("disabled", true);
  }

  #enableSubmitButton() {
    this.#submitButton.removeAttribute("disabled");
  }

  async #onFormSubmit(event) {;
    event.preventDefault();

    this.#disableSubmitButton();
    this.#rememberOrForgetInfo();
    try {
      await this.#sendFormInfo();
      EventBus.event("final-thoughts-finished");
    } catch (error) {
      this.#enableSubmitButton();
    }
  }

  async #sendFormInfo() {
    MessageBus.debug("FinalThoughtsFormSend :: ini")

    const name = this.#form.querySelector("#final-thoughts-name").value;
    const email = this.#form.querySelector("#final-thoughts-email").value;
    const comment = this.#form.querySelector("#final-thoughts-comment").value;

    try {
      await APP.playcocolaAPI.sendFinalThoughts(name, email, comment);
      MessageBus.debug("FinalThoughtsFormSend :: end");
    } catch (error) {
      MessageBus.error("FinalThoughtsFormSend :: error", error);
      throw error;
    }
  }

  #checkIfInfoIsRemembered() {
    if (Cookies.get("name") != undefined && Cookies.get("name") != "") {
      this.#form.querySelector("#final-thoughts-name").value = Cookies.get("name");
    }

    if (Cookies.get("email") != undefined && Cookies.get("email") != "") {
      this.#form.querySelector("#final-thoughts-email").value = Cookies.get("email");
    }

    if (Cookies.get("remember") != undefined) {
      this.#rememberInfoCheck.checked = true;
    }
  }

  #rememberOrForgetInfo() {
    const checked = this.#rememberInfoCheck.checked;
    if (checked) {
      Cookies.set("name", this.#form.querySelector("#final-thoughts-name").value, { expires: 90 });
      Cookies.set("email", this.#form.querySelector("#final-thoughts-email").value, { expires: 90 });
      Cookies.set("remember", true, { expires: 90 });
    } else {
      Cookies.remove("name");
      Cookies.remove("email");
      Cookies.remove("remember");
    }
  }

  #checkIfSubmitButtonShouldBeEnabledOrDisabled() {
    let result =
      Array(...this.#form.querySelectorAll(".form-control")).some((element) => {
        return element.value.trim() !== "";
      });

    if (result) {
      this.#enableSubmitButton();
    } else {
      this.#disableSubmitButton();
    }
  }
}

import { MessageBus } from "./message_bus.js";
import { Utils } from "./utils.js";

export class PlaycocolaAPI {
  #playGatheringApiUrl
  #apiToken
  playGathering

  constructor({
    playGatheringApiUrl,
    apiToken
  } = {}) {
    this.#playGatheringApiUrl = playGatheringApiUrl;
    this.#apiToken = apiToken;
  }

  async intPlayGathering() {
    try {
      const data = (await this.requestPlayGatheringInfo()).data;
      this.setPlayGathering(data);
    } catch (error) {
      MessageBus.error("On intPlayGathering()", error);
    };
  }

  setPlayGathering(data) {
    this.playGathering = {};

    this.playGathering.playSessionApiURL = data.play_session_api_url;
    this.playGathering.uuid = data.play_gathering.uuid;
    this.playGathering.title = data.play_gathering.title;
    this.playGathering.description = data.play_gathering.description;
    this.playGathering.playtesterExpectations = data.play_gathering.playtester_expectations;
    this.playGathering.coverUrl = data.play_gathering.cover_url;
    this.playGathering.playtestingSessionMinutes = data.play_gathering.playtesting_session_minutes;
    this.playGathering.fullGameplayMinutes = data.play_gathering.full_gameplay_minutes;
    this.playGathering.genres = data.play_gathering.genres;
    this.playGathering.playtesterReward = data.play_gathering.playtester_reward;
    this.playGathering.requiredCheckboxes = data.play_gathering.required_checkboxes;
    this.playGathering.afterRequiredCheckboxesDescription = data.play_gathering.after_required_checkboxes_description;
    this.playGathering.afterSessionFinishedDescription = data.play_gathering.after_session_finished_description;
    this.playGathering.gameBuildUrl = data.play_gathering.game_build_url;
    this.playGathering.isFinished = data.play_gathering.is_finished;
    this.playGathering.finishAt = data.play_gathering.finish_at;
  }

  // async requestPlayGatheringInfoMock() {
  //   const data = {};
  //   data.play_gathering = {};

  //   data.play_session_api_url = "PLAY_SESSION_API_URL";
  //   data.play_gathering.uuid = "UUID";
  //   data.play_gathering.title = "TITLE";
  //   data.play_gathering.description = "DESCRIPTION";
  //   data.play_gathering.cover_url = null;
  //   data.play_gathering.playtesting_session_minutes = null;
  //   data.play_gathering.full_gameplay_minutes = null;
  //   data.play_gathering.genres = [];
  //   data.play_gathering.playtester_reward = null;
  //   data.play_gathering.required_checkboxes = [];
  //   data.play_gathering.after_required_checkboxes_description = null;
  //   data.play_gathering.after_session_finished_description = null;
  //   data.play_gathering.game_build_url = null;

  //   return data;
  // }

  // async requestPlayGatheringInfoMock() {
  //   const data = {};
  //   data.play_gathering = {};

  //   data.play_session_api_url = "PLAY_SESSION_API_URL";
  //   data.play_gathering.uuid = "UUID";
  //   data.play_gathering.title = "TITLE";
  //   data.play_gathering.description = "DESCRIPTION";
  //   data.play_gathering.cover_url = "https://placehold.co/600x300";
  //   data.play_gathering.playtesting_session_minutes = 30;
  //   data.play_gathering.full_gameplay_minutes = 120;
  //   data.play_gathering.genres = ["genre_1", "genre_2"];
  //   data.play_gathering.playtester_reward = "PLAYTESTER_REWARD";
  //   data.play_gathering.required_checkboxes = ["check box **1**", "check box **3**"];
  //   data.play_gathering.after_required_checkboxes_description = "AFTER_REQUIRED_CHECKBOXES_DESCRIPTION";
  //   data.play_gathering.after_session_finished_description = "AFTER_SESSION_FINISHED_DESCRIPTION";
  //   data.play_gathering.game_build_url = "GAME_BUILD_URL";

  //   return data;
  // }

  requestPlayGatheringInfo() {
    return axios.request({
        method: "get",
        url: this.#playGatheringApiUrl,
        headers: this.#headers()
      });
  }

  sendDebugEvent(value) {
    let formData = new FormData();
    formData.append("tag", "client-action");
    formData.append("value", value);

    axios.request({
      method: "post",
      url: this.playGathering.playSessionApiURL + "/event",
      data: formData,
      headers: this.#headers()
    }).catch((error) => {
      console.error("On sendDebugEvent()", error);
    });
  }

  async sendSystemInfo() {
    MessageBus.debug("updateSystemInfo :: ini");
    let uaParserData = Utils.getUserAgentData();
    let media_devices = await Utils.getMediaDevices()

    let data = {
      user_agent: uaParserData,
      media_devices: media_devices
    }

    try {
      await axios.request({
        method: "post",
        data: data,
        url: this.playGathering.playSessionApiURL + "/update_system_info",
        headers: this.#headers()
      });

      MessageBus.debug("updateSystemInfo :: end");
    } catch (error) {
      MessageBus.error("updateSystemInfo :: error", error);
    }
  }

  async uploadVideoPart(date, blob, progressCallback = null) {
    const filename = "video_part_" + date + ".webm";
    const formData = new FormData();
    formData.append("video_part", blob, filename);

    await axios.request({
      method: "post",
      url: this.playGathering.playSessionApiURL + "/video_part",
      data: formData,
      headers: this.#headers(),
      onUploadProgress: (p) => {
        // console.log("progress: ", p);

        if (progressCallback)
          progressCallback(p.progress);
      }
    });
  }

  async sendFinalThoughts(name, email, comment) {
    let formData = new FormData();
    formData.append("user_name", name);
    formData.append("user_email", email);
    formData.append("user_comment", comment);

    await axios.request({
      method: "post",
      url: this.playGathering.playSessionApiURL + "/comment",
      data: formData,
      headers: this.#headers()
    });
  }

  async sendSignalSessionFinalized() {
    MessageBus.debug("sendSignalSessionFinalized :: ini");

    try {
      await axios.request({
        method: "post",
        url: this.playGathering.playSessionApiURL + "/session_finalized",
        headers: this.#headers()
      });

      MessageBus.debug("sendSignalSessionFinalized :: end");
    } catch (error) {
      MessageBus.error("sendSignalSessionFinalized :: error", error);
    }
  }

  async sendTimedComment(second_at_formatted, flair, comment) {
    MessageBus.debug("sendTimedComment :: ini");

    let formData = new FormData();
    formData.append("second_at_formatted", second_at_formatted);
    formData.append("flair", flair);
    formData.append("comment", comment);

    await axios.request({
      method: "post",
      url: this.playGathering.playSessionApiURL + "/add_timed_comment",
      data: formData,
      headers: this.#headers()
    });

    MessageBus.debug("sendTimedComment :: end");
  }

  #headers() {
    return { "Authorization": "Playcocola " + this.#apiToken };
  }
}

import { Effects } from "./effects.js";
import { MessageBus } from "./message_bus.js";
import { APP } from "./app.js";

export class Uploader {
  #progressBlock
  #progressElement
  #progressBarElement
  #uploadsQueueCounterElement

  #blogsToUploadQueue
  #isUploading
  #isRecoveringFromError
  #lastUploadPercentageDebugEvent

  onUploadsFinishedCallback

  constructor() {
    this.#blogsToUploadQueue = []
    this.#isUploading = false
    this.#isRecoveringFromError = false

    this.#progressBlock = document.querySelector("#uploading-progress-block");
    this.#progressElement = this.#progressBlock.querySelector(".progress");
    this.#progressBarElement = this.#progressBlock.querySelector(".progress-bar");
    this.#uploadsQueueCounterElement = document.querySelector("#uploads-queue-counter");

    this.#lastUploadPercentageDebugEvent = 0;

    this.onUploadsFinishedCallback = null;
  }

  // API :: INI
  addBlobToUploadQueue(blob, date = Date.now(), retries_counter = 0) {
    this.#blogsToUploadQueue.push(new Uploader.BlobToUpload(blob, date, retries_counter));
    this.#updateUploadQueueCounter();
    this.#uploadNextBlob();

    console.log("addBlobToUploadQueue().#blogsToUploadQueue.length: ", this.#blogsToUploadQueue.length);
  }

  isUploadingActive() {
    return this.#isUploading || this.#isRecoveringFromError
  }
  // API :: END


  async #uploadVideoPart(video_part_data) {
    MessageBus.debug("uploadVideoPart :: ini");
    Effects.delayedFadeIn(this.#progressBlock);
    this.#isUploading = true;
    this.#uploadProgressBarUpdate(0);

    try {
      await
        APP.playcocolaAPI.uploadVideoPart(
          video_part_data.date,
          video_part_data.blob,
          (value) => { this.#uploadProgressBarUpdate(value) }
        );

      MessageBus.debug("uploadVideoPart :: end");

      if (this.#blogsToUploadQueue.length === 0) {
        this.onUploadsFinishedCallback?.();
      }

      this.#isUploading = false;
      this.#uploadNextBlob();
    } catch (error) {
      const error_message = error.response?.data?.error || "Error uploading the video";
      const retrievable = error.response?.data?.retrievable !== undefined ? error.response?.data?.retrievable : true;

      console.log("#uploadVideoPart.error.retrievable: ", retrievable);

      MessageBus.error(error_message, error);

      this.#isUploading = false;

      // Sending it back to the queue
      if (retrievable) {
        console.log("#uploadVideoPart.error.retrying");
        this.addBlobToUploadQueue(video_part_data.blob, video_part_data.date, video_part_data.retries_counter + 1);

        this.#isRecoveringFromError = true;

        // Wait X seconds
        setTimeout(() => {
          this.#isRecoveringFromError = false;
          this.#uploadNextBlob();
        }, 20000);
      } else {
        this.#uploadNextBlob();
      }

    } finally {
      this.#updateUploadQueueCounter();
    }
  }

  #uploadProgressBarUpdate(percentage) {
    if (percentage > this.#lastUploadPercentageDebugEvent + 0.05 || percentage < this.#lastUploadPercentageDebugEvent) {
      this.#lastUploadPercentageDebugEvent = percentage;
      this.#progressElement.setAttribute("aria-valuenow", percentage * 100);
      this.#progressBarElement.style.width = (percentage * 100) + "%";
    }
  }

  #uploadNextBlob() {
    console.log("#uploadNextBlob()");

    if (this.isUploadingActive() || this.#blogsToUploadQueue.length === 0)
      return;

    const video_part_data = this.#blogsToUploadQueue.shift();
    this.#uploadVideoPart(video_part_data);
    this.#updateUploadQueueCounter();
  }

  #updateUploadQueueCounter() {
    if (this.isUploadingActive()) {
      Effects.delayedFadeIn(this.#progressBlock);
    } else {
      Effects.delayedFadeOut(this.#progressBlock);
    }

    this.#uploadsQueueCounterElement.innerHTML = this.#blogsToUploadQueue.length + 1;
  }

  static BlobToUpload = class BlobToUpload {
    blob
    date
    retries

    constructor(blob, date = Date.now(), retries = 0) {
      this.blob = blob;
      this.date = date;
      this.retries = retries;
    }
  }
}

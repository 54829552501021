import { MessageBus } from "./message_bus.js";
import { AudioStreamAnalyzer } from "./audio_stream_analyser.js";

export class SimpleRecorder {
  screenStream = null;
  micStream = null;
  fullStream = null;

  micAudioTrack = null;
  screenVideoTrack = null;
  screenAudioTrack = null;
  fullAudioTrack = null;

  audioCtx = null;
  audioDestination = null;
  micAnalyzer

  mimeType = null;
  recordedChunks = [];
  isMicActive = false;

  stopTimeoutID
  isRecording
  waitingForCloseStreams

  constructor() {
    this.isRecording = false;
    this.waitingForCloseStreams = false;
  }

  resetStreams() {
    this.screenStream = null;
    this.micStream = null;
    this.fullStream = null;

    this.micAudioTrack = null;
    this.screenVideoTrack = null;
    this.screenAudioTrack = null;
    this.fullAudioTrack = null;
  }

  async initStreams() {
    if (this.screenStream === null) {
      await this.initScreenStream();
    }

    if (this.micStream === null && this.isMicActive) {
      await this.initMicStream();
    }
  }

  closeStreams() {
    console.log("SimpleRecorder.closeStreams()");

    if (this.getFullStream() != null)
      this.getFullStream().getTracks().forEach(track => track.stop());

    if (this.micStream != null)
      this.micStream.getTracks().forEach(track => track.stop());

    if (this.screenStream != null)
      this.screenStream.getTracks().forEach(track => track.stop());

    this.resetStreams();
  }

  resetMediaRecorder() {
    this.mediaRecorder = null;
  }

  // ScreenStream :: INI
  async initScreenStream() {
    try {
      this.screenStream =
        await navigator.mediaDevices.getDisplayMedia({
          video: {
            height: {
              max: 1080
            },
            // frameRate: {
            //   ideal: 30,
            //   max: 30,
            // },
          },
          audio: true
        });

      if (this.screenStream.getAudioTracks().length > 0) {
        this.screenAudioTrack = this.screenStream.getAudioTracks()[0];
      } else {
        MessageBus.debug("initScreenStream - No Audio Tracks in the screenStream");
      }

      if (this.screenStream.getVideoTracks().length > 0) {
        this.screenVideoTrack = this.screenStream.getVideoTracks()[0];
      } else {
        throw new Error("No Video Tracks in the screenStream");
      }

      console.log("initScreenStream - Success");

    } catch (error) {
      MessageBus.error("initScreenStream - Error: \n\nThere was a problem activating the screen capture. It may be caused for a security setting. Try to reset your screen capture settings for this domain.", error);
      throw error;
    }
  }
  // ScreenStream :: END


  // MicStream :: INI
  async initMicStream() {
    try {
      this.micStream =
        await navigator.mediaDevices.getUserMedia({
          video: false,
          audio: true
        });
      console.log("initMicStream - Success");

      if (this.micStream.getAudioTracks().length > 0) {
        this.micAudioTrack = this.micStream.getAudioTracks()[0];

        if (this.fullStream !== null) {
          this.injectMicStreamIntoAudioDestination();
        }

      } else {
        throw new Error("No Audio Tracks in the micStream");
      }

    } catch (error) {
      MessageBus.error("initMicStream - Error: \n\nThere was a problem activating the Mic. It may be caused for a security setting. Try to reset your mic settings for this domain.", error);
    }
  }

  async setMicActive(value) {
    this.isMicActive = value;

    if (value && this.micAudioTrack === null) {
      await this.initMicStream();
    }

    if (this.micAudioTrack !== null) {
      this.micAudioTrack.enabled = value;
    } else {
      if (value) { // no warning if we are requesting deactivation
        console.warn("SimpleRecorder.setMicActive() - No micAudioTrack found");
      }

    }
  }

  injectMicStreamIntoAudioDestination() {
    const streamSource = this.audioCtx.createMediaStreamSource(this.micStream);
    streamSource.connect(this.audioDestination);

    // Create analyser
    this.micAnalyzer = new AudioStreamAnalyzer(this.audioCtx, streamSource, 32);
  }
  // MicStream :: END

  // Silenced Audio Track :: INI
  injectSilencedAudioTrack() {
    const oscillator = this.audioCtx.createOscillator();
    const gainNode = this.audioCtx.createGain();
    gainNode.gain.value = 0;
    oscillator.connect(gainNode);
    gainNode.connect(this.audioDestination);
    oscillator.start();
  }
  // Silenced Audio Track :: END

  // MediaRecorder :: INI
  getFullStream() {
    console.log("getFullStream(): ", this.fullStream);
    if (this.fullStream !== null) {
      return this.fullStream;
    }

    // Create fullStream
    this.fullStream = new MediaStream();

    // Video Track
    if (this.screenVideoTrack !== null) {
      this.fullStream.addTrack(this.screenVideoTrack);
    } else {
      console.error("SimpleRecorder.getFullStream() - No Video Tracks in screenStream");
      APP.playcocolaAPI.sendDebugEvent("SimpleRecorder.getFullStream() - No Video Tracks in screenStream");
    }

    // create fullAudioTrack
    this.audioCtx = new AudioContext();
    this.audioDestination = this.audioCtx.createMediaStreamDestination();
    this.fullAudioTrack = this.audioDestination.stream.getAudioTracks()[0];

    // Screen Audio
    if (this.screenAudioTrack !== null) {
      console.log("AudioTracks in screenStream - Found");
      // const streamSource = this.audioCtx.createMediaStreamSource(this.screenStream);
      // streamSource.connect(this.audioDestination);

      // Reduce sound from screen audio:
      const gainNode = this.audioCtx.createGain();
      const streamSource = this.audioCtx.createMediaStreamSource(this.screenStream);
      streamSource.connect(gainNode);
      gainNode.connect(this.audioDestination);
      gainNode.gain.value = 0.1;
    } else {
      console.log("AudioTracks in screenStream - No Found");
    }

    // Mic Audio
    if (this.micAudioTrack !== null) {
      this.injectMicStreamIntoAudioDestination();
    }

    // Inject silenced audio track if not audio tracks found
    // This is a workaround for this bug in Chrome: https://bugs.chromium.org/p/chromium/issues/detail?id=1490888
    if (this.micAudioTrack === null && this.screenAudioTrack === null) {
      this.injectSilencedAudioTrack();
    }

    // Add the audioDestination fullAudioTrack to the full Stream.
    this.fullStream.addTrack(this.fullAudioTrack);

    return this.fullStream;
  }

  getMimeType() {
    if (this.mimeType !== null) {
      return this.mimeType;
    }

    const mimeTypes = [
      "video/webm;codecs=vp9",
      // "video/webm;codecs=vp8",
      // "video/webm;codecs=h264",
      // "video/webm;codecs=opus",
      // "video/x-matroska"
      "video/webm",
      // "video/mp4"
    ]

    const mimeTypeSupported = mimeTypes.find((e) => MediaRecorder.isTypeSupported(e));

    if (mimeTypeSupported) {
      this.mimeType = mimeTypeSupported;
      console.log("Using mimeType: " + this.mimeType);
      return this.mimeType;

    } else {
      MessageBus.error("Not any mimeType supported. We recommend use another browser. Supported browsers are Chrome, Firefox and Edge.", new Error("Not any mimeType supported"));
      return null;
    }
  }

  initMediaRecorder() {
    this.mediaRecorder = new MediaRecorder(this.getFullStream(), { mimeType: this.getMimeType() });

    this.mediaRecorder.ondataavailable = (event) => {
      this.onDataAvailable(event);
    };
  }

  onDataAvailable(event) {
    console.log("SimpleRecorder.onDataAvailable.size: ", event.data.size);
    this.recordedChunks.push(event.data);
  }

  // API :: INI
  record(seconds, shouldCloseStreamsOnFinish = false) {
    if (this.isRecording) {
      console.warn("SimpleRecorder.record when still instate isRecording");
      return;
    }

    if (this.mediaRecorder == undefined) {
      this.initMediaRecorder();
    }

    this.isRecording = true;
    this.recordedChunks = [];
    this.waitingForCloseStreams = shouldCloseStreamsOnFinish;

    return new Promise((resolve, reject) => {
      this.mediaRecorder.onstop = () => {
        console.log("SimpleRecorder.onstop()");
        this.isRecording = false;
        const blob = this.crunchRecordedChunks();
        resolve(blob);
        if (this.waitingForCloseStreams) {
          this.closeStreams();
        }
      };

      this.mediaRecorder.onerror = (error) => {
        MessageBus.error("An error was detected while trying to record the video", error);
        this.isRecording = false;
        reject(error);
      };

      APP.playcocolaAPI.sendDebugEvent("SimpleRecorder.mediaRecorder.start()");
      try {
        this.mediaRecorder.start();
      } catch (error) {
        MessageBus.error("An error was detected while starting to record the video", error);
        this.isRecording = false;
        reject(error);
      }

      if (this.isRecording) {
        console.log("SimpleRecorder.setting.timeout");

        this.stopTimeoutID =
          setTimeout(() => {
            console.log("SimpleRecorder.timeout");
            this.mediaRecorder.stop();
          }, seconds * 1000);
      }
    });
  }

  stop(shouldCloseStreams = false) {
    if (!this.mediaRecorder) {
      console.warn("SimpleRecorder.stop() - mediaRecorder not found");
      return;
    }

    if (this.isRecording) {
      this.waitingForCloseStreams = shouldCloseStreams;
      clearTimeout(this.stopTimeoutID);
      this.mediaRecorder.stop();
    } else {
      this.closeStreams();
    }
  }
  // API :: END

  // MediaRecorder :: END

  crunchRecordedChunks() {
    // const fixedChunks = this.recordedChunks.splice(0, this.recordedChunks.length); // Fix bug in Chrome that generates corrupted blobs:
    const blob = new Blob(this.recordedChunks, { type: this.getMimeType() });
    return blob;
  }
}

import { AppModal } from "./modal.js";
import { APP } from "./app.js";

export class MessageBus {
  static modal =
    new AppModal(
      document.querySelector("#info-modal"),
      document.querySelector("#alert-modal"),
      document.querySelector("#thanks-modal"),
    );

  static debug(message) {
    console.log(message, (new Error).stack.split("\n")[2].trim());
    try {
      APP.playcocolaAPI.sendDebugEvent(message);
    } catch (error) {
      console.warn("Error sending DebugEvent", error);
    }
  }

  static info(message, callback = null) {
    console.log(message, (new Error).stack.split("\n")[2].trim());
    this.modal.info(message, callback);
    APP.playcocolaAPI.sendDebugEvent(message);
  }

  static thanks(callback = null) {
    console.log("Thanks modal", (new Error).stack.split("\n")[2].trim());
    this.modal.thanks(callback);
  }

  static error(message, error) {
    console.error(message, error);

    let errorStack = "No Error.stack";
    if (error.stack) {
      errorStack = error.stack.split("\n")[1].trim().replace("https", "").replace("http", "")
    }

    const fullMessage = `
${message}

${error.message}

<small class="font-monospace fw-light text-secondary">${errorStack}</small>
`
    this.modal.alert(fullMessage);
    APP.playcocolaAPI.sendDebugEvent(fullMessage);
  }
}

import { Utils } from "./utils.js";

export class SimpleTimer {
  isPaused = null;
  pausedAt = null;
  pausedTime = null;
  initAt = null;
  tickCallback = null;

  constructor(tickCallback = null) {
    this.isPaused = true;
    this.pausedTime = 0;
    this.tickCallback = tickCallback;
  }

  start() {
    this.isPaused = false;
    this.initAt = this.getSystemTime();
    this.tick();
  }

  timeToString() {
    return Utils.timeFormatted(this.getTime());
  }

  tick() {
    if (this.isPaused)
      return;

    if (this.tickCallback !== null) {
      // console.log("this.tickCallback: ", this.tickCallback);
      this.tickCallback();
    }

    setTimeout(() => { this.tick(); }, 1000);
  }

  pause() {
    this.isPaused = true;
    this.pausedAt = this.getSystemTime();
  }

  resume() {
    this.isPaused = false;
    this.pausedTime += (this.getSystemTime() - this.pausedAt)
    this.tick();
  }

  getTime() {
    return (this.getSystemTime() - this.initAt) - this.pausedTime;
  }

  getSystemTime() {
    return (new Date().getTime());
  }
}

export class Utils {
  static getParamValue(name) {
    return new URLSearchParams(window.location.search).get(name);
  }

  static timeFormatted(time = new Date().getTime()) {
    const hours = Math.floor(time / 1000 / 60 / 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const seconds = Math.floor((time / 1000) % 60);
    var timeString = minutes.toString().padStart(2, "0") + ":" + seconds.toString().padStart(2, "0");

    if (hours > 0)
      timeString = hours.toString().padStart(2, "0") + ":" + timeString;

    return timeString;
  }


  static getUserAgentData() {
    return UAParser();
  }

  static async getMediaDevices() {
    let result = [];

    if (!navigator.mediaDevices?.enumerateDevices) {
      console.warn("enumerateDevices() not supported.");
    } else {
      // List cameras and microphones.
      await navigator.mediaDevices
        .enumerateDevices()
        .then((devices) => {
          devices.forEach((device) => {
            result.push(device.label)
          });
        })
        .catch((err) => {
          console.error(`${err.name}: ${err.message}`);
        });
    }

    return result
  }

  static map_range(value, low1, high1, low2, high2) {
    return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
  }

  static markdown(text) {
    marked.use({
      breaks: true, // break line on a single line break
    });
    return DOMPurify.sanitize(marked.parse(text));
  }
}

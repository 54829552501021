import { EventBus } from "./event_bus.js";
import { MessageBus } from "./message_bus.js";
import { APP } from "./app.js";

export class TimedComment {
  #modalElement
  #form
  #submitButton
  #pauseRecordingCheck

  #modal

  constructor() {
    this.#modalElement = document.querySelector("#timed-comment-modal");
    this.#form = document.querySelector("#timed-comment-form");
    this.#submitButton = this.#form.querySelector("button[type=submit]");
    this.#pauseRecordingCheck = document.querySelector("#timed-comment-pause-recording-check");

    this.#modal = new bootstrap.Modal(this.#modalElement, {});

    this.#setEvents();
    this.#checkIfPauseRecordingChecked();
    this.#checkIfSubmitButtonShouldBeEnabledOrDisabled();
  }

  async #setEvents() {
    this.#form.addEventListener("submit", (event) => {
      this.#formSend(event);
    });

    this.#pauseRecordingCheck.addEventListener("change", (event) => {
      this.#togglePauseRecording(this.#pauseRecordingCheck.checked);
    });

    this.#form.querySelector("#timed-comment-comment").addEventListener("input", (event) => {
      this.#checkIfSubmitButtonShouldBeEnabledOrDisabled();
    });

    this.#modalElement.addEventListener('hidden.bs.modal', event => {
      this.#modalClosed();
    });

    this.#modalElement.addEventListener('show.bs.modal', event => {
      this.#modalOpened();
    });
  }

  #modalOpened() {
    this.#togglePauseRecording(this.#pauseRecordingCheck.checked);
  }

  #modalClosed() {
    if (this.#pauseRecordingCheck.checked) {
      EventBus.event("timed-comment-toggle-recording-pause", false);
    }
  }

  #closeModal() {
    this.#modal.hide();
  }

  #disableSubmitButton() {
    this.#submitButton.setAttribute("disabled", true);
  }

  #enableSubmitButton() {
    this.#submitButton.removeAttribute("disabled");
  }

  async #formSend(event) {
    MessageBus.debug("TimedCommentFormSend :: ini");
    event.preventDefault();

    this.#disableSubmitButton();

    const second_at_formatted = document.querySelector("#timed-comment-timer-display").innerHTML.trim();
    const flair = this.#form.querySelector("input[name=flair]:checked").value;
    const comment = this.#form.querySelector("#timed-comment-comment").value;

    try {
      await APP.playcocolaAPI.sendTimedComment(second_at_formatted, flair, comment);
      MessageBus.debug("TimedCommentFormSend :: end");
      this.#closeModal();
      this.#form.querySelector("#timed-comment-comment").value = "";
    } catch (error) {
      MessageBus.error("TimedCommentFormSend :: error", error);
    }
  }

  #checkIfPauseRecordingChecked() {
    this.#pauseRecordingCheck.checked = (Cookies.get("pause-recording-while-commenting") == "true")
  }

  #togglePauseRecording(value) {
    Cookies.set("pause-recording-while-commenting", value);
    EventBus.event("timed-comment-toggle-recording-pause", value);
  }

  #checkIfSubmitButtonShouldBeEnabledOrDisabled() {
    let result = this.#form.querySelector("#timed-comment-comment").value.trim() !== "";

    if (result) {
      this.#enableSubmitButton();
    } else {
      this.#disableSubmitButton();
    }
  }
}
